.font-size-14 {
  font-size: 12px;
}

.DashboardMenu {
  cursor: pointer;
  padding: 15px;
  text-align: center;
  border: 0.5px solid #bbb;
  font-size: 16px;
}

.DashboardMenu:hover {
  transform: scale(1.02);
}

/* .question-background{
  background-image: url("");
} */

.examselectStudent {
  width: 32%;
  background-color: #fff;
  text-align: center;
  padding: 10px;
  border: 1px solid #000;
}

.examunselectStudent {
  width: 32%;
  background-color: #eee;
  text-align: center;
  padding: 10px;
}

div {
  font-size: 14px;
  /* font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif */
  /* font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
  /* font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; */

  /* font-family: Georgia, 'Times New Roman', Times, serif; */
  font-family: monospace;
}

table {
  font-family: arial, sans-serif;
  /* border-collapse: collapse; */
  width: 100%;
}

td, th {
  /* border: 1px solid #000; */
  text-align: left;
  padding: 5px 8px;
  /* padding: 8px; */
  font-family: 'Courier New', Courier, monospace;
}

table {
  border: 0.5px solid #eee;
}

th {
  background-color: #fff;
  /* border: 1px solid #000; */
}

tbody {
  /* border: 1px solid #000; */
}

input {
  padding: 0px 7px;
  border: 1px solid #000;
  border-radius: 3px;
}

input:focus {
  border: 1px solid #000;
  outline: 0;
  border-radius: 3px;
}

textarea {
  padding: 0px 7px;
  border: 1px solid #000;
  border-radius: 3px;
}

textarea:focus {
  border: 1px solid #000;
  outline: 0;
  border-radius: 3px;
}

tr:nth-child(odd) {
  background-color: #f9f9f9;
  overflow-x: scroll;
}

tr:nth-child(even) {
  background-color: #fff;
  overflow-x: scroll;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

div.dataTables_wrapper {
  width: 800px;
  margin: 0 auto;
}

.column-style-1 {
  width: 20%;
}

.optionPayment {
  border: 0.5px solid #000;
  padding: 10px;
  cursor: pointer;
}

.optionPayment:hover {
  background-color: rgb(204, 238, 209);
  /* transform: scale(1.1); */
}

.remaining-amount {
  border: 1px solid #000;
  border-radius: 5px;
  padding: 10px;
  margin: 5px 0;
}

.userDetailsText {
  font-size: 16px;
}

.main-width-full {
  width: 100%;
}

@media screen and (min-width: 1000px) {
  .sidebar-design {
    min-height: 100vh;
  }

  .sidebar-width-expand {
    width: 20%;
  }

  .main-width-expand {
    width: 80%;
  }


  .sidebar-small-screen {
    display: none;
  }

  .small-display-block {
    display: none;
  }

  .bars-position {
    padding: 7px;
    width: 40px;
  }

  .name-header-postion {
    text-align: right;
    width: 100%;
    padding: 7px;
    cursor: pointer;
  }
}

@media screen and (max-width: 1000px) {
  .sidebar-width-expand {
    display: none;
  }

  .bars-position {
    width: 100%;
    text-align: center;
  }

  .name-header-postion {
    text-align: center;
    width: 100%;
    cursor: pointer;
  }

  .small-display-none {
    display: none;
  }

  .sidebar-small-screen {
    display: block;
    position: fixed;
    z-index: 1;
    background-color: #00000000;
    padding-left: 15%;
    padding-top: 5%;
    background-color: rgba(0, 0, 0, 0.4);
    overflow-y: scroll;
    height: 100%;
  }

  .sidebar-design {
    width: 90%;
    background-color: #fff;
    height: 90%;
    overflow-y: scroll;
  }

  .main-width-expand {
    width: 100%;
  }
}

.active-sidebar {
  display: flex;
  background-color: #eee;
  border-bottom: 0.5px solid #999;
  align-items: center;
  padding: 12px 10px 12px 20px;
  font-weight: bold;
  color: #666;
}

.active-sidebar-font {
  font-weight: bold;
  font-size: 16px;
  color: #000;
}

.sidebar-width-expand {
  width: 20%;
}

.sidebar-width-without-expand {
  width: 10%;
}


.main-width-without-expand {
  width: 90%;
}

.passive-sidebar {
  display: flex;
  align-items: center;
  border-bottom: 0.5px solid #ddd;
  color: #666;
  padding: 12px 10px 12px 20px;
}

.passive-sidebar-font {
  font-size: 14px;
  color: #000;
}

.header-design {
  padding: 20px;
  background-color: #fff;
  border-left: 1px solid #eee;
}

.Link {
  color: #000;
  text-decoration-line: none;
}

Link {
  color: #000;
  text-decoration-line: none;
}

.form-view {
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  border: 5px solid #eee;
}

.input-common {
  width: 250px;
  padding: 4px;
  border: 1px solid #999;
  border-radius: 3px;
  font-size: 12px;
  font-family: "Open Sans";
}

.input-common:focus {
  border: 1.5px solid #999;
}

.input-common-2 {
  padding: 4px;
  border: 1px solid #999;
  border-radius: 3px;
  font-size: 12px;
  font-family: "Open Sans";
}

.input-common-2:focus {
  border: 1.5px solid #999;
}

.input-common-3 {
  width: 100%;
  padding: 10px 4px;
  margin: 0;
  border: 1.5px solid #fff;
  /* border-radius: 3px; */
  font-size: 12px;
  font-family: "Open Sans";
}

.input-common-3:focus {
  border: 1.5px solid #999;
}

.tab-style1 {
  border-bottom: 2px solid #000;
  color: #000;
  cursor: pointer;
  padding: 2px 30px;
  text-align: center;
  font-weight: bold;
  font-size: 15px;
  display: inline-block;
  margin: 20px 0;
}


.tab-style2 {
  border-bottom: 1px solid #999;
  color: #999;
  padding: 2px 30px;
  cursor: pointer;
  text-align: center;
  font-size: 13px;
  margin: 20px 0;
}

.active-option {
  border: 2px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  font-weight: bold;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 10px;
  color: #000;
}

.passive-option {
  border: 2px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  background-color: rgba(255, 255, 255, 0);
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 10px;
}



.dot-pulse-2 {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #000;
  color: #000;
  box-shadow: 9999px 0 0 -5px #000;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: .25s;
}

.dot-pulse-2::before, .dot-pulse::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #000;
  color: #000;
}

.dot-pulse-2::before {
  box-shadow: 9984px 0 0 -5px #000;
  animation: dotPulseBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse-2::after {
  box-shadow: 10014px 0 0 -5px #000;
  animation: dotPulseAfter 1.5s infinite linear;
  animation-delay: .5s;
}

.homeImage{
  width: 100vw;
  height: 80vw;
}